import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faUnlink, faLink } from '@fortawesome/free-solid-svg-icons'

import '../../assets/scss/app-styles/users.scss'

class ExtensionDetails extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
            'Content-Type': 'application/json'
        }
    }


	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}

    getDomains = (tenantId, sync) => {
        const payLoad = {
            tenant_id: tenantId
        };
        if(sync){
            payLoad["sync"] = true;
        }
        axios.post(`${process.env.REACT_APP_API_URL}getRemoteDomains`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            response.data.map((row)=>(
                items.push(<option value={row.domain}>{row.domain + " - " + row.site_name}</option>)
            ));
            this.setState({domainItems: items});
        }).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

	componentDidMount() {
		if(!this.props.location.state){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}

        this.setState({delink: false});
		if (this.props.location.state.extension) {
            this.getDomains(this.props.location.state.tenant_id, false);
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				extension: parseInt(this.props.location.state.extension),
				tenant_id: this.props.location.state.tenant_id
			};
			axios.post(`${process.env.REACT_APP_API_URL}getUserDetails`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
                this.setState({originalEmail: data.emailAddress});
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.firstName || !this.state.data.lastName  || !this.state.data.emailAddress) {
            toast.error('Fields with * are mandatory!');
            return;
		}
		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		let payLoad = {
            extension: this.props.location.state.extension.toString(),
            tenant_id: this.props.location.state.tenant_id,
            user_details: this.state.data,
            delink: this.state.delink,
            original_email: this.state.originalEmail
        }
        
		axios.post(`${process.env.REACT_APP_API_URL}editUserDetails`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});

		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}
    delinkHandler  =(value)=> {
        this.setState({delink: value});
        if(value){
            toast.info("This email will now be un-link upon save!");
        } else {
            toast.info("This email will now not be un-linked upon save!");
        }
    }
	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/tenants',
                state: {tenant_id: this.props.location.state.tenant_id,
                    tenantLevelDesktopAppEnabled: this.props.location.state.tenantLevelDesktopAppEnabled,
                    tenantLevelMobileAppEnabled: this.props.location.state.tenantLevelMobileAppEnabled,
                    selectedTenantName: this.props.location.state.selectedTenantName}
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Extensions" title="Details" parent_url="extensions"  />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.extension? "Edit User Information For Extension " + this.props.location.state.extension : "Add Extension"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        First Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="firstName"
                                        onChange={this.inputChangeHandler} value={this.state.data.firstName}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Last Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="lastName"
                                        onChange={this.inputChangeHandler} value={this.state.data.lastName}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="emailAddress"
                                        onChange={this.inputChangeHandler} value={this.state.data.emailAddress}
                                        disabled={this.props.location.state.isLinked === "true" && !this.state.delink}/>

                                    {
                                        this.props.location.state.isLinked === "true"?
                                            this.state.delink? 
                                                <FontAwesomeIcon 
                                                    icon={faUnlink} className="custom-data-table-icon tenant-refresh"
                                                    title="Un-link Extension"
                                                    style={{marginRight: "12px", color: "red"}}
                                                    onClick={e => this.delinkHandler(false)}/>
                                                : 
                                                <FontAwesomeIcon 
                                                    icon={faLink} className="custom-data-table-icon tenant-refresh"
                                                    title="Link Extension"
                                                    style={{marginRight: "12px"}}
                                                    onClick={e => this.delinkHandler(true)}/>
                                        : null
                                    }
                                    
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left">
                                        Mobile Phone
                                    </label>
                                    <input type="text" className="form-control" name="mobilePhone"
                                        onChange={this.inputChangeHandler} value={this.state.data.mobilePhone}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Home Phone
                                    </label>
                                    <input type="text" className="form-control" name="homePhone"
                                        onChange={this.inputChangeHandler} value={this.state.data.homePhone}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left">
                                        Domain
                                    </label>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="domain" value={this.state.data.domain}>
                                        {this.state.domainItems}
                                    </select>
                                    <FontAwesomeIcon 
                                        icon={faSync} className="custom-data-table-icon tenant-refresh"
                                        title="Sync Domains List"
                                        onClick={e => this.getDomains(this.props.location.state.tenant_id,true)}/>
                                </div>

                            </div>
                            <div className="row form-group">
                                

                            </div>



                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default ExtensionDetails;
