import React, { useState, useEffect, Fragment } from 'react';
import logo from '../assets/images/vtsl_logo_black.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";

import '../assets/scss/app-styles/signin.scss'

import axios from "axios";
import { SignIn, LOGIN, Username, Password, CloudId } from '../constant';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const AppSignin = ({ history }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [changePassword, setChangePassword] = useState(false)

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);

    const [resetPasswordUi, setResetPasswordUi] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [attr, setAttr] = useState(true);
    const [error, setError] = useState("");

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );

    useEffect(() => {
        const appUsername  = localStorage.getItem("appUsername");
        setUsername(appUsername);
    }, []);

    const changePasswordHandler = () => {
        if(newPassword !== confirmPassword){
            setAttr(true);
            setError('Passwords doesnot match!');
            return;
        }

        if(passwordStrength < 4){
            setAttr(true);
            // setError('Password should have atleast 1 of $@#&! special character, 1 number, 1 lower case letter and 1 upper case letter!');
            setError('Password is weak');
            return;
        }
        const usernameLowered = username.toLowerCase();
        const payLoad = {
            username: usernameLowered,
            old_password: password,
            new_password: newPassword
        }
        axios.post(`${process.env.REACT_APP_API_URL}changeAppPassword`, payLoad)
        .then(response=>{
            setPassword('');
            setChangePassword(false);
        })
        .catch(err=>{
            toast.error(err.response.data);
        });

    }

    const passwordKeyUpHandler = (evt) => {
        const strengthbar = document.getElementById("meter");
        const pass = evt.target.value;
        setError('');
        var strength = 0;
        if (pass.match(/[a-z]+/)) {
            strength += 1;
        }
        if (pass.match(/[A-Z]+/)) {
            strength += 1;
        }
        if (pass.match(/[0-9]+/)) {
            strength += 1;
        }
        if (pass.match(/[$@#&!]+/)) {
            strength += 1;

        }

        if (pass.length < 8) {
            setError('Minimum number of characters are 8');
            strength -= 1;
        }
        if (pass.length > 100) {
            setError('Maximum number of characters are 100');
            strength -= 1;
        } 
        // if (pass.length >=6 && pass.length <= 12){
            
        // }

        switch (strength) {
            case 0:
            strengthbar.style.width = '0%';
            strengthbar.style.backgroundColor = "red";
            break;

            case 1:
            strengthbar.style.width = '25%';
            strengthbar.style.backgroundColor = "red";
            break;

            case 2:
            strengthbar.style.width = '50%';
            strengthbar.style.backgroundColor = "orange";
            break;

            case 3:
            strengthbar.style.width = '75%';
            strengthbar.style.backgroundColor = "orange";
            break;

            case 4:
            strengthbar.style.width = '100%';
            strengthbar.style.backgroundColor = "#22af47";
            break;
        }

        setPasswordStrength(strength);
    }


    const resetPassword = () => {
        if(!username){
            toast.error("Username is mandatory to reset Password")
            return;
        }
        const usernameLowered = username.toLowerCase();
        const payLoad = {
            username: usernameLowered
        }
        axios.post(`${process.env.REACT_APP_API_URL}forgetAppPassword`, payLoad)
        .then(response=>{
            toast.info("Please Follow the link emailed to reset the password!");
            setResetPasswordUi(false);
        })
        .catch(err=>{
            toast.error(err.response.data);
        });
    }


    const loginAuth = async () => {
        // axios call here 
        //TODO:  also get profile image here of user and store it in profileURL

        if(username==="test_account2@vtsl.net" && password === "1234test2"){
            window.location.href = "csc:1001:1971@APPLE";
            return;
        }  else if (username === "test_account3@vtsl.net" && password === "1234test3"){
            window.location.href = "csc:1002:0179@APPLE";
            return;
        }else if (username === "test_account1@vtsl.net" && password === "1234test1"){
            window.location.href = "csc:1000:0097@GOOGLE";
            return;
        }else if (username === "test_account4@vtsl.net" && password === "1234test4"){
            window.location.href = "csc:1003:5770@GOOGLE";
            return;
        }
        const newUsername = username.replace(/\s/g, '');
        const creds = {
            "username" : newUsername,
            "password" : Buffer.from(password).toString('base64')
        };
        setAttr('disabled');
        axios.post(`${process.env.REACT_APP_API_URL}appLogin`,creds)
        .then(response =>{ 
            
            if(response.status === 200){
                // href csc as for turbored.
                let usernameLowered = newUsername.toLowerCase();
                usernameLowered = encodeURIComponent(usernameLowered);
                let myPassword = Buffer.from(password).toString('base64');
                //let myPassword = password;
                let code  = "csc:" + usernameLowered + ":" + myPassword + "@VTSL";
                if(process.env.REACT_APP_BUILD === "dev"){
                    code  = "csc:" + usernameLowered + ":" + myPassword + "@VTSL*";
                }
                window.location.href = code;
                localStorage.setItem("appUsername", newUsername);
            } else if (response.status === 202){
                setChangePassword(true);
                setNewPassword('');
                setConfirmPassword('');
            }
            
        })
        .catch(err=>{
        
            console.log(err);
            setAttr(true);
            setError('Invalid Credentials');
        
        });
    
    }


    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="app-auth-bg">
                    <div className="authentication-box">
                        <div className="text-center">
                            <img width="200" src={logo} alt="" />
                        </div>
                        <div className="card mt-4 card-app">
                            {
                                changePassword? 

                                    <div className="card-body">
                                        <div className="text-center">
                                            <h4>{"Change Password"}</h4>
                                            <h6>{"Enter your new Password"} </h6>
                                        </div>
                                        <form className="theme-form"> 
                                            
                                            <div className="form-group">
                                                <input className="form-control password-eye" type={showNewPassword? "text":"password"} name="newPassword"
                                                    value={newPassword} placeholder={"New Password"}
                                                    onChange={e => {setNewPassword(e.target.value); setAttr(false)}}
                                                    onKeyUp={passwordKeyUpHandler}
                                                />
                                                <div className="eye-password" 
                                                    onClick={e => setShowNewPassword(!showNewPassword)}>
                                                    <FontAwesomeIcon icon={showNewPassword?faEyeSlash:faEye} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control password-eye" type={showConfirmPassword? "text":"password"} name="confirmPassword"
                                                    value={confirmPassword} placeholder={"Confirm Password"}
                                                    onChange={e => {setConfirmPassword(e.target.value); setError(''); setAttr(false);}}/>
                                                <div className="eye-password" 
                                                    onClick={e => setShowConfirmPassword(!showConfirmPassword)}>
                                                    <FontAwesomeIcon icon={showConfirmPassword?faEyeSlash:faEye} />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                {/* <progress max="100" value="0" id="meter"></progress> */}
                                                <div className="progress"><div id="meter" className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" ></div></div>
                                                <h6 style={{color: "red"}} >{error} </h6>
                                            </div>
                                            <div className="form-group form-row mt-3 mb-0">
                                                <button className="btn btn-block btn-login" disabled={attr}
                                                    type="button" onClick={() => changePasswordHandler()} >
                                                        {"Change Password"}
                                                </button>
                                            </div>
                                            
                                        </form>

                                        <p className="mb-2 text-muted forgot-p">Back to Sign In?
                                                <a style={{fontWeight: "800", cursor: "pointer", color: "black"}} 
                                                    onClick={() => {setChangePassword(false); setError(''); setAttr(false);}}> Click Here</a>
                                            </p>
                                    </div>

                                : 
                                    <div className="card-body">
                                        <form className="theme-form"> 
                                            {/* <div className="form-group">
                                                <label className="col-form-label pt-0">{CloudId}</label>
                                                <input className="form-control" type="text" name="cloudId"
                                                    value={cloudId}
                                                    onChange={e => setCloudId(e.target.value)}
                                                />
                                            </div> */}
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="username"
                                                    value={username} placeholder={resetPasswordUi? "Email":Username}
                                                    onChange={e => {setUsername(e.target.value);  setError(''); setAttr(false);}}
                                                />
                                            </div>
                                            {
                                                !resetPasswordUi? 
                                                    <div className="form-group">
                                                        <input className="form-control password-eye" type={showPassword? "text":"password"} name="password"
                                                            value={password} placeholder={Password}
                                                            onChange={e => {setPassword(e.target.value);  setError(''); setAttr(false);}}/>
                                                            <div className="eye-password" 
                                                                onClick={e => setShowPassword(!showPassword)}>
                                                                <FontAwesomeIcon icon={showPassword?faEyeSlash:faEye} />
                                                            </div>
                                                    </div>
                                                : null 
                                            }
                                            <div className="text-center">
                                                
                                                <h6 style={{color: "red"}} >{error} </h6>
                                            </div>
                                            <div className="form-group form-row mt-3 mb-0">
                                                {
                                                    resetPasswordUi? 
                                                        <button className="btn btn-block btn-login" disabled={attr}
                                                            type="button" onClick={resetPassword} >
                                                                Reset
                                                        </button>
                                                        :

                                                    <button className="btn btn-block btn-login" disabled={attr}
                                                        type="button" onClick={() => loginAuth()} >
                                                            {SignIn}
                                                    </button>
                                                }
                                            </div>
                                            
                                        </form>
                                        {
                                            resetPasswordUi? 
                                            <p className="mb-2 text-muted forgot-p">Back to Sign In?
                                                <a style={{fontWeight: "800", cursor: "pointer", color: "black"}} 
                                                    onClick={e => {setResetPasswordUi(false); setAttr(true)}}> Click Here</a>
                                            </p>
                                            :
                                            <p className="mb-2 text-muted forgot-p">Forgot Password?
                                                <a style={{fontWeight: "800", cursor: "pointer", color: "black"}} 
                                                    onClick={e => {setResetPasswordUi(true); setAttr(false)}}> Reset</a>
                                            </p>
                                        }
                                        
                                    </div>
                            }
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(AppSignin);